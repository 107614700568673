export const SHORT_FILM_DURATION = 40;

export const LARGE_DEVICE_WIDTH = 1188;
export const MEDIUM_DEVICE_WIDTH = 768;

export const MOVIES_LARGE_DEVICE_WIDTH_COUNT_LIMIT = 12;
export const MOVIES_MEDIUM_DEVICE_WIDTH_COUNT_LIMIT = 8;
export const MOVIES_SMALL_DEVICE_WIDTH_COUNT_LIMIT = 5;

export const MOVIES_LARGE_DEVICE_WIDTH_COUNT_LIMIT_EXTENSION = 3;
export const MOVIES_MEDIUM_DEVICE_WIDTH_COUNT_LIMIT_EXTENSION = 2;